import React, { Component, PureComponent } from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {
    Container,
    Grid,
    Paper,
    TextField,
    Button,
    Box,
    CircularProgress,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import {
    ALL_ROUTES,
    seriesColummList,
    dateFilter,
    FILTER_TYPES
} from '../../constants.js';

import _ from 'lodash';

am4core.useTheme(am4themes_animated);

const style = {
    paper: {
        width: '100%'
    },
    landingPageButtonWarpper: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "baseline"
    },
    dateFilter: {
        color: 'black'
    }
};

class DynamicCharts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            summaryData: props.summaryData,
            selectedFilterType: props.selectedFilterType,
            allTemplates: null,
            componentLoaded: false
        }
    }

    async componentDidMount() {
        const { summaryData } = _.cloneDeep(this.state)
        const allTemplates = await this.getAllCharts(summaryData);
        await this.setState({
            allTemplates: allTemplates,
            componentLoaded: true
        })

        if (summaryData && summaryData.length > 0) {
            const sortedList = _.sortBy(summaryData, "healthSystemName")
            _.each(sortedList, async (value, index) => {
                const divId = `${value.healthSystemName} - ${index}`
                await this.createChart(divId, [{ ...value }], "totalCount", "name", this.chart, "totalCount")
            })
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.summaryData === this.props.summaryData
    }

    createChart = async (div, responseData, valueY, categoryX, chartObject, seriesName) => {
        const countList = _.map(responseData, valueY)
        const divId = document.getElementById(div)
        if (!divId) return

        const divChartId = document.getElementById(div)
        if (divChartId && divChartId.chart) {
            await divChartId.chart.dispose()
        }
        let chart = am4core.create(div, am4charts.XYChart);
        chart.logo.disabled = true

        chart.paddingRight = 20;

        chart.data = responseData

        //******************************************************************************************* */

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "bottom";
        chart.legend.labels.template.fontSize = 10;

        // Create axes
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "healthSystemName";

        // if (this.state.selectedFilterType.value === "PURPOSE_OF_USE_WISE") {
        //     categoryAxis.title.text = "Purpose of uses";
        // }

        // if (this.state.selectedFilterType.value === "EHR_WISE") {
        //     categoryAxis.title.text = "EHRs";
        // }

        // if (this.state.selectedFilterType.value === "LOCATION_WISE") {
        //     categoryAxis.title.text = "Health System Locations";
        // }

        // if (this.state.selectedFilterType.value === "FACILITY_WISE") {
        //     categoryAxis.title.text = "Health Systems";
        // }

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        valueAxis.title.text = "Requests";
        valueAxis.min = 0;
        valueAxis.strictMinMax = true
        valueAxis.renderer.labels.template.fontSize = 10;

        _.each(seriesColummList, function (column) {
            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = column.field
            series.dataFields.categoryX = "healthSystemName";
            series.name = `${column.name}`;
            series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
            series.columns.template.fillOpacity = 0.8;
            series.columns.template.width = am4core.percent(80);
            series.columns.template.fill = am4core.color(column.color)

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.interactionsEnabled = false;
            bullet.dy = -10;
            // bullet.label.text = "{valueY}";
            // bullet.label.fill = am4core.color("#ffff");

            var valueLabel = series.bullets.push(new am4charts.LabelBullet());
            valueLabel.label.text = "{valueY}";
            valueLabel.label.fontSize = 10;
            valueLabel.label.verticalCenter = "bottom"

            return series;
        })

        // Adjust category spacing
        categoryAxis.renderer.cellStartLocation = 0.1; // Adjust as needed
        categoryAxis.renderer.cellEndLocation = 0.9;   // Adjust as needed
        categoryAxis.renderer.labels.template.fontSize = 10;

        //******************************************************************************************* */
        // // Create axes

        // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = categoryX;
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 30;

        // categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
        //     if (target.dataItem && target.dataItem.index & 2 == 2) {
        //         return dy + 25;
        //     }
        //     return dy;
        // });

        // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.max = _.max(countList) + 500

        // console.log(" _.max(countList) :::: ",  _.max(countList) + 50)

        // // Create series
        // var series = chart.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = valueY;
        // series.dataFields.categoryX = categoryX;
        // series.name = seriesName;
        // series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        // series.columns.template.fillOpacity = .8;

        // var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        // valueLabel.label.text = "{valueY}";
        // valueLabel.label.fontSize = 20;
        // valueLabel.label.verticalCenter = "bottom"

        // var columnTemplate = series.columns.template;
        // columnTemplate.strokeWidth = 2;
        // columnTemplate.strokeOpacity = 1;

        // series.columns.template.events.on("hit", function (ev) {
        //     console.log("Event ::: ", ev.target.dataItem.categories.categoryX)
        // })

        chartObject = chart;

        // Add simple vertical 
        // chart.scrollbarY = new am4core.Scrollbar();

        // Add horizotal scrollbar with preview
        // var scrollbarX = new am4charts.XYChartScrollbar();
        // scrollbarX.series.push(series);

        // scrollbarX.thumb.background.fill = am4core.color("#d9d9d9")
        // scrollbarX.thumb.background.fillOpacity = 0.8
        // scrollbarX.minHeight = 10

        // if (this.state.selectedFilterType.value === "PURPOSE_OF_USE_WISE" || this.state.selectedFilterType.value === "EHR_WISE") {
        //     chart.scrollbarX = scrollbarX;
        //     chart.scrollbarX.start = 0;
        //     chart.scrollbarX.end = 3.0;
        //     chart.scrollbarX.parent = chart.bottomAxesContainer;
        // }

        // if (this.state.selectedFilterType.value === "LOCATION_WISE") {
        //     chart.scrollbarX = scrollbarX;
        //     chart.scrollbarX.start = 0;
        //     chart.scrollbarX.end = 0.07;
        //     chart.scrollbarX.parent = chart.bottomAxesContainer;
        // }

        // if (this.state.selectedFilterType.value === "FACILITY_WISE") {
        //     chart.scrollbarX = scrollbarX;
        //     chart.scrollbarX.start = 0;
        //     chart.scrollbarX.end = 0.20;
        //     chart.scrollbarX.parent = chart.bottomAxesContainer;
        // }
    }

    getAllCharts = async () => {
        const { summaryData } = _.cloneDeep(this.state)
        const sortedList = _.sortBy(summaryData, "healthSystemName")
        return (
            <>
                {
                    (sortedList.length > 0
                        ? sortedList
                        : []
                    ).map((row, index) => (
                        <Grid key={row.healthSystemName} item xs={6} md={4}>
                            <Paper className='respurce-list-paper'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <div id={row.healthSystemName + ' - ' + index} style={{ width: "100%", height: "500px", paddingBottom: "1rem" }}></div>
                                            </Grid>
                                        </Grid>
                                    </Grid >
                                </Grid>
                            </Paper>
                        </Grid>
                    ))
                }
            </>
        )
    }

    componentWillUnmount() {
        const { summaryData } = _.cloneDeep(this.state)
        if (summaryData && summaryData.length > 0) {
            const sortedList = _.sortBy(summaryData, "healthSystemName")
            _.each(sortedList, async (value, index) => {
                const divId = document.getElementById(`${value.healthSystemName} - ${index}`)
                if (divId && divId.chart) {
                    divId.chart.dispose()
                }
            })
        }
    }

    render() {
        const { classes } = this.props;
        const {
            allTemplates,
            componentLoaded,
            summaryData
        } = _.cloneDeep(this.state)

        if (componentLoaded) {
            if (summaryData && summaryData.length > 0) {
                const sortedList = _.sortBy(summaryData, "healthSystemName")
                _.each(sortedList, (value, index) => {
                    const divId = `${value.healthSystemName} - ${index}`
                    this.createChart(divId, [{ ...value }], "totalCount", "name", this.chart, "totalCount")
                })
            }
        }
        return (
            <>
                {
                    allTemplates ? allTemplates : (
                        <Grid container className='all-resouces-list-wrapper' spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Paper className='respurce-list-paper' style={{ height: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <div className='CustLodder circular-loader'>
                                                        <Box className='CustLodder' padding={1}>
                                                            <Box className={classes.textAlignCenter}>
                                                                <CircularProgress size={40} />
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid >
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid >
                    )
                }
            </>
        )
    }
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(style)(DynamicCharts)));